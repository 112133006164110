import clsx from "clsx";

const DEFAULT_LOADING_STYLES = ["rounded-full", "absolute"];

type FancyLoading = {
  isSendingData?: boolean;
  containerHeightClass?: string;
  widthClassName?: string;
  heightClassName?: string;
};

export default function FancyLoader({
  isSendingData,
  containerHeightClass,
  widthClassName,
  heightClassName,
}: FancyLoading) {
  const defaultStyles = clsx(
    ...DEFAULT_LOADING_STYLES,
    widthClassName || "w-40",
    heightClassName || "h-36"
  );
  return (
    <div
      className={clsx(
        "m-auto",
        "grid",
        "place-items-center",
        containerHeightClass || "h-48"
      )}
    >
      <div className={clsx("flex", "items-center", "justify-center")}>
        <div
          className={clsx(
            defaultStyles,
            "animate-[spin_600ms_linear_infinite]",
            "border-[#333354]",
            "border-l-4",
            "delay-700"
          )}
        />
        <div
          className={clsx(
            defaultStyles,
            "animate-[spin_750ms_linear_infinite]",
            "border-[#333354]",
            "border-l-2",
            "delay-200"
          )}
        />
        <div
          className={clsx(
            defaultStyles,
            "animate-[spin_1.5s_linear_infinite]",
            "border-[#C6C6D0]",
            "border-l-2",
            "delay-500"
          )}
        />
        <div
          className={clsx(
            defaultStyles,
            "animate-[spin_1s_linear_infinite]",
            "border-[#C6C6D0]",
            "border-l-4",
            "delay-700"
          )}
        />
        <div
          className={clsx(
            defaultStyles,
            "animate-[spin_1.5s_linear_infinite]",
            "border-[#FF5701]",
            "border-l-4",
            "delay-1000"
          )}
        />
        <div
          className={clsx(
            defaultStyles,
            "animate-[spin_1.35s_linear_infinite]",
            "border-[#FF5701]",
            "border-l-8",
            "delay-500"
          )}
        />
        {isSendingData && (
          <>
            <div
              className={clsx(
                defaultStyles,
                "inwardScale_400",
                "border-[#333354]",
                "border-l-4",
                "delay-700"
              )}
            />
            <div
              className={clsx(
                defaultStyles,
                "inwardScale_500",
                "border-[#333354]",
                "border-l-2",
                "delay-200"
              )}
            />
            <div
              className={clsx(
                defaultStyles,
                "inwardScale_1000",
                "border-[#C6C6D0]",
                "border-l-2",
                "delay-500"
              )}
            />
            <div
              className={clsx(
                defaultStyles,
                "inwardScale_700",
                "border-[#C6C6D0]",
                "border-l-4",
                "delay-700"
              )}
            />
            <div
              className={clsx(
                defaultStyles,
                "inwardScale_1000",
                "border-[#FF5701]",
                "border-l-4",
                "delay-1000"
              )}
            />
            <div
              className={clsx(
                defaultStyles,
                "inwardScale_900",
                "border-[#FF5701]",
                "border-l-8",
                "delay-500"
              )}
            />
            <div
              className={clsx(
                defaultStyles,
                "inwardScale_900",
                "border-[#FF5701]",
                "border-l-8",
                "delay-500"
              )}
            />
          </>
        )}
      </div>
    </div>
  );
}
